<template>
  <div class="map" ref="locationMap"></div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      google: null,
      map: null
    };
  },
  computed: {
    ...mapGetters(["getGoogle", "getUserLocation",'getHideMap'])
  },
  methods: {
    initializeMap() {
      this.google = this.getGoogle;
      if (this.google) {
        const mapContainer = this.$refs.locationMap;
        this.map = new this.google.maps.Map(mapContainer, {
          styles: [
            {
              featureType: "administrative",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#444444"
                }
              ]
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#378b90"
                }
              ]
            },
            {
              featureType: "administrative.neighborhood",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#31b9c1"
                }
              ]
            },
            {
              featureType: "landscape",
              elementType: "all",
              stylers: [
                {
                  color: "#f2f2f2"
                }
              ]
            },
            {
              featureType: "poi",
              elementType: "all",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "road",
              elementType: "all",
              stylers: [
                {
                  saturation: -100
                },
                {
                  lightness: 45
                }
              ]
            },
            {
              featureType: "road.highway",
              elementType: "all",
              stylers: [
                {
                  visibility: "simplified"
                }
              ]
            },
            {
              featureType: "road.arterial",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "transit",
              elementType: "all",
              stylers: [
                {
                  visibility: "off"
                }
              ]
            },
            {
              featureType: "water",
              elementType: "all",
              stylers: [
                {
                  color: "#46bcec"
                },
                {
                  visibility: "on"
                }
              ]
            },
            {
              featureType: "water",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#31b9c1"
                }
              ]
            },
            {
              featureType: "water",
              elementType: "geometry.stroke",
              stylers: [
                {
                  color: "#31b9c1"
                }
              ]
            }
          ],
          disableDefaultUI: true,
          zoom: 6,
          // ukraine location by default
          center: {
            lat: 48.383022,
            lng: 31.1828699
          }
        });
      }
    }
  },
  watch: {
    watch: {
      // 'getHideMap'(to, from) {
      //   this.initializeMap();
      // }
    },
    // getGoogle: function() {
    //   this.initializeMap();
    // },
    // getUserLocation: function(getUserLocation) {
    //   this.map.setCenter(getUserLocation);
    // }
  },
  mounted() {
    // this.initializeMap();
  }
};
</script>
