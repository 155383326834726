<template>
  <section class="program-rules">
    <div
      class="program-rules__animation"
      ref="rulesAnimation"
      data-rellax-speed="5"
      data-rellax-percentage="0.5"
      v-if="!getResponsive"
    ></div>
    <div class="container">
      <div class="row">
        <div class="col-xx-lg-5 col-x-lg-5 col-lg-5 col-md-5 col-xs-12">
          <h1 class="program-rules__title">
            Правила та умови програми підтримки пацієнтів ТерапіяПЛЮС
          </h1>
          <div class="program-rules__img" v-if="getResponsive">
            <img src="~@/assets/img/program-rules-img.png" alt="" />
          </div>
          <router-link
            @click.native="programRulesClick()"
            to="/terms-and-conditions"
            class="program-rules__btn"
          >
            Ознайомитись
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Rellax from "rellax";
export default {
  name: "ProgramRules",
  computed: {
    ...mapGetters(["getResponsive"])
  },
  methods: {
    programRulesClick() {
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "click_button_learn" });
    }
  },
  mounted() {
    new Rellax(this.$refs.rulesAnimation);
  }
};
</script>
