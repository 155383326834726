<template>
  <section class="create-account">
    <div class="container">
      <h2 class="create-account__title">
        Особистий кабінет та додаткові сервіси
      </h2>
      <div class="row">
        <div class="col-xx-lg-6 col-x-lg-6 col-lg-6 col-md-6 col-xs-12">
          <div class="create-account__item">
            <div class="row">
              <div class="col-xx-lg-4 col-x-lg-4 col-xs-12">
                <div class="create-account__item-img">
                  <img src="~@/assets/img/create-account-icon-1.png" alt="" />
                </div>
              </div>
              <div class="col-xx-lg-8 col-x-lg-8 col-xs-12">
                <div class="create-account__item-text">
                  При реєстрації у Програмі учасник Програми отримує доступ до
                  <strong>Особистого Кабінету.</strong>
                </div>
                <div class="create-account__item-text">
                  Доступ в Особистий Кабінет здійснюється учасником через
                  <strong> індивідуальний логін та пароль.</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xx-lg-6 col-x-lg-6 col-lg-6 col-md-6 col-xs-12">
          <div class="create-account__item">
            <div class="row">
              <div class="col-xx-lg-4 col-x-lg-4 col-xs-12">
                <div class="create-account__item-img">
                  <img src="~@/assets/img/create-account-icon-2.png" alt="" />
                </div>
              </div>
              <div class="col-xx-lg-8 col-x-lg-8 col-xs-12">
                <div class="create-account__item-text">
                  В Особистому Кабінеті Ви завжди можете переглянути
                  <strong>
                    історію <br />
                    Ваших покупок
                  </strong>
                  по Програмі ТерапіяПЛЮС.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="create-account__btn">
      <router-link @click.native="registrationClick()" to="/registration"
        >Створити аккаунт</router-link
      >
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    registrationClick() {
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "click_button_create" });
    }
  }
};
</script>
