import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import GoogleMapsApiLoader from "google-maps-api-loader";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        infoPopup: false,
        registrationFormData: null,
        googleApiKey: process.env.VUE_APP_GOOGLE_MAP_KEY,
        google: "",
        confirmedLocation: false,
        locationConfirmModal: false,
        locationAddressModal: false,
        outsideCountryModal: false,
        nearestPharmacyModal: false,
        restorePasswordModal: false,
        hotlineModal: false,
        contactsSuccessModal: false,
        contactsErrorModal: false,
        hideMap: true,
        hideMapBlock: true,

        userLocation: "",
        pharmacies: [],
        pharmaciesContext: null,
        mapMarkers: [],
        preparats: [
            // {
            //     name: "Атаканд",
            //     text: "КАНДЕСАРТАНУ ЦИЛЕКСЕТИЛ / таблетки / 16мг №28",
            //     discount: "50",
            // },
            // {
            //     name: "Атаканд",
            //     text: "КАНДЕСАРТАНУ ЦИЛЕКСЕТИЛ / таблетки / 8мг №28",
            //     discount: "50",
            // },
            {
                name: "Брилінта",
                text: "ТИКАГРЕЛОР	/ таблетки / 90мг №56",
                discount: "30",
            },
            {
                name: "Брилінта",
                text: "ТИКАГРЕЛОР	/ таблетки / 60мг №56",
                discount: "30",
            },
            {
                name: "Комбогліза XR",
                text: "САКСАГЛІПТИН + МЕТФОРМІН	/таблетки 2.5мг/1000мг №28",
                discount: "20",
            },
            {
                name: "Комбогліза XR",
                text: "САКСАГЛІПТИН + МЕТФОРМІН /	таблетки 5мг/1000мг №28",
                discount: "20",
            },
            {
                name: "Крестор",
                text: "РОЗУВАСТАТИН /	таблетки 40мг №28",
                discount: "20",
            },
            {
                name: "Крестор",
                text: "РОЗУВАСТАТИН /	таблетки 5мг №28",
                discount: "20",
            },
            {
                name: "Крестор",
                text: "РОЗУВАСТАТИН /	таблетки 20мг №28",
                discount: "20",
            },
            {
                name: "Крестор",
                text: "РОЗУВАСТАТИН /	таблетки 10мг №28",
                discount: "20",
            },
            {
                name: "Онгліза",
                text: "САКСАГЛІПТИН / таблетки 2.5мг №30",
                discount: "20",
            },
            {
                name: "Онгліза",
                text: "САКСАГЛІПТИН /	таблетки 5мг №30",
                discount: "20",
            },
            // {
            //     name: "Симбікорт Турбухалер",
            //     text: "БУДЕСОНІД + ФОРМОТЕРОЛ /	160/4.5мкг/доза, 60 доз",
            //     discount: "25",
            // },
            // {
            //     name: "Симбікорт Турбухалер",
            //     text: "БУДЕСОНІД + ФОРМОТЕРОЛ /	80/4.5мкг/доза, 60 доз",
            //     discount: "25",
            // },
            {
                name: "ТРИКСЕО АЕРОСФЕРА",
                text: "ФОРМОТЕРОЛ + ГЛІКОПІРОНІЙ + БУДЕСОНІД / 5 / 7,2 / 160 мкг",
                discount: "50",
            },
            {
                name: "Форксіга",
                text: "ДАПАГЛІФЛОЗИН / таблетки 10мг №30",
                discount: "20",
            },
            {
                name: "Ксігдуо Пролонг",
                text: "ДАПАГЛІФЛОЗИН + МЕТФОРМІН /	таблетки 10мг/1000 мг №28",
                discount: "20",
            },
            {
                name: "Ксігдуо Пролонг",
                text: "ДАПАГЛІФЛОЗИН + МЕТФОРМІН / таблетки 5мг/1000 мг №28",
                discount: "20",
            },
        ],
        currentCoordinates: {
            lat: "",
            lng: "",
        },
        isEnabledResponsive: false,
        registrationProcess: {
            step: 1,
            formData: "",
        },
        status: "",
        token: localStorage.getItem("token") || "",
        user: {},
        DS: [],
        tempMapMarkers: [],
        isOpenMarkerOnMap: false,
        clearInputs: false,
        geolocationOpened: false,
        thereIsNoDrugStoresGoAway: false,
        PhoneAlreadyExist: false,
        CardAlreadyExist: false,
        CardDoesntExist: false,
        CardDoesntFind: false,
    },
    mutations: {
        showThereIsNoDrugStoresGoAway(state, value) {
            state.thereIsNoDrugStoresGoAway = value;
        },
        showCardAlreadyExist(state, value) {
            state.CardAlreadyExist = value;
        },
        showCardDoesntExist(state, value) {
            state.CardDoesntExist = value;
        },
        showCardDoesntFind(state, value) {
            state.CardDoesntFind = value;
        },
        showPhoneAlreadyExist(state, value) {
            state.PhoneAlreadyExist = value;
        },
        setGeolocationOpened(state, value) {
            state.geolocationOpened = value;
        },
        setClearInputs(state, value) {
            state.clearInputs = value;
        },
        convertToTempMapMarkers(state, drugStores) {
            let dataItems = drugStores
            let array = []


            dataItems.forEach((el, index) => {
                let temp = {
                    city: el.City,
                    position: {
                        lat: el.Lat,
                        lng: el.Lon,
                    },
                    title: el.Name,
                    address: el.Address,
                    phones: [el.Tel1, el.Tel2],
                    timeWork: {
                        weekdays: el.Worktime,
                        weekend: "",
                    },
                };
                array.push(temp);
            });

            state.pharmacies = array
        },

        setOpenMarkerOnMap(state, value) {
            this.isOpenMarkerOnMap = value;
        },
        setRestorePasswordModal(state, value) {
            state.restorePasswordModal = value;
        },
        setDS(state, value) {
            state.DS = value;
        },
        setNearestPharmacyModal(state, value) {
            state.nearestPharmacyModal = value;
        },
        setContactsSuccess(state, value) {
            state.contactsSuccessModal = value;
        },
        setContactsError(state, value) {
            state.contactsErrorModal = value;
        },
        setHotline(state, value) {
            state.hotlineModal = value;
        },
        setCurrentCoordinates(state, value) {
            state.currentCoordinates = value;
        },
        setPharmaciesContext(state, context) {
            state.pharmaciesContext = context;
        },
        setPharmaciesList(state, value) {
            state.pharmacies = value;
        },
        setGoogleMapApi(state, google) {
            state.google = google;
        },
        setUserLocation(state, {coordinates, cityName, countryName}) {
            state.userLocation = {
                city: cityName,
                country: countryName,
                lat: coordinates.lat,
                lng: coordinates.lng,
            };
            localStorage.curLat = coordinates.lat;
            localStorage.curLon = coordinates.lng;
            localStorage.setItem("userLocation", JSON.stringify(state.userLocation));
        },
        setLocationConfirmModal(state, value) {
            state.locationConfirmModal = value;
        },
        setLocationAddressModal(state, value) {
            state.locationAddressModal = value;
        },
        setOutsideCountryModal(state, value) {
            state.outsideCountryModal = value;
        },

        setInfoPopUp(state, value) {
            state.infoPopup = value;
        },
        // Begin registration process
        setRegistrationStep(state, step) {
            state.registrationProcess.step = step;
        },
        setRegistrationFormData(state, formData) {
            state.registrationProcess.formData = formData;
        },
        // Begin responsive
        setResponsive(state, value) {
            state.isEnabledResponsive = value;
        },
        // Begin register and login user
        authRequest(state) {
            state.status = "loading";
        },
        setMapMarkers(state, value) {
            state.mapMarkers = value;
        },
        authSuccess(state, token, user) {
            state.status = "success";
            state.token = token;
            state.user = user;
        },
        authError(state) {
            state.status = "error";
        },
        logout(state) {
            state.status = "";
            state.token = "";
        },
        showMap(state, data) {
            state.hideMap = data
        },
        showHideMapBlock(state, data) {
            state.hideMapBlock = data
        }

    },
    getters: {

        getInfoPopup(state) {
            return state.infoPopup
        },
        getHideMap(state) {
            return state.hideMap
        },
        getHideMapBlock(state) {
            return state.hideMapBlock
        },
        canIShowThereIsNoDrugStoresGoAway(state) {
            return state.thereIsNoDrugStoresGoAway;
        },
        canIShowCardAlreadyExist(state) {
            return state.CardAlreadyExist;
        },
        canIShowCardDoesntExist(state) {
            return state.CardDoesntExist;
        },
        canIShowCardDoesntFind(state) {
            return state.CardDoesntFind;
        },
        canIShowPhoneAlreadyExist(state) {
            return state.PhoneAlreadyExist;
        },
        geolocationOpened(state) {
            return state.geolocationOpened;
        },
        getClearInputs(state) {
            return state.clearInputs;
        },
        getConvertedToTempMapMarkers(state) {
            return state.pharmacies;
        },
        getOpenMarkerOnMap(state) {
            return state.isOpenMarkerOnMap;
        },
        getDS(state) {
            return state.DS;
        },
        getCurrentCoordinates(state) {
            return state.currentCoordinates;
        },
        getPharmaciesContext(state) {
            return state.pharmaciesContext;
        },
        getPharmaciesList(state) {
            return state.pharmacies;
        },
        // Begin get google map api
        getGoogleApiKey(state) {
            return state.googleApiKey;
        },
        // Begin get modals data
        getUserLocation(state) {
            return state.userLocation;
        },
        getConfirmedLocation(state) {
            return state.confirmedLocation;
        },
        getLocationConfirmModal(state) {
            return state.locationConfirmModal;
        },
        getLocationAddressModal(state) {
            return state.locationAddressModal;
        },
        getOutsideCountryModal(state) {
            return state.outsideCountryModal;
        },
        getNearestPharmacyModal(state) {
            return state.nearestPharmacyModal;
        },
        getHotlineModal(state) {
            return state.hotlineModal;
        },
        getRestorePasswordModal(state) {
            return state.restorePasswordModal;
        },
        getContactsSuccessModal(state) {
            return state.contactsSuccessModal;
        },
        getContactsErrorModal(state) {
            return state.contactsErrorModal;
        },
        // Begin get google maps and user location data
        getGoogle(state) {
            return state.google;
        },
        getMapMarkers(state) {
            return state.mapMarkers;
        },

        // Begin get preparats
        getPreparats(state) {
            return state.preparats;
        },
        // Begin get registration process
        getRegistrationStep(state) {
            return state.registrationProcess.step;
        },
        getRegistrationFormData(state) {
            return state.registrationProcess.formData;
        },
        // Begin responsive
        getResponsive(state) {
            return state.isEnabledResponsive;
        },
    },
    actions: {
        async setGoogleMapApi(context) {
            await GoogleMapsApiLoader({
                apiKey: context.state.googleApiKey,
            }).then((googleMap) => {
                context.commit("setGoogleMapApi", googleMap);
            });
        },
        // Begin user login
        userLogin(context, data) {
            context.commit("authRequest");
            delete axios.defaults.headers.common["X-Access-Token"];

            let FormDataSend = new FormData()
            FormDataSend.append("login", data.login)
            FormDataSend.append("password_lo", data.password)
            FormDataSend.append("enter", "увійти")
            FormDataSend.append("action", "login")
            axios
                .post(
                    "https://terapiaplus.com.ua/api/login",
                    FormDataSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then((response) => {
                    const token = response.data.token;
                    const user = response.data.user;
                    localStorage.setItem("token", token);
                    axios.defaults.headers.common["X-Access-Token"] = token;
                    context.commit("authSuccess", token, user);
                })
                .catch((error) => {
                    context.commit("authError", error);
                    localStorage.removeItem("token");
                });
        },
        // Begin user registration
        async userRegistration(context, data) {
            context.commit("authRequest");
            var newPhone = data.phone
                .replaceAll(/ /g, "");
            var d = new FormData();
            d.append("card", data.card);
            d.append("first_name", data.name);
            d.append("last_name", data.surname);
            d.append("middle_name", data.patronymic);
            d.append("phone", newPhone);
            d.append("email", data.email);
            await axios
                .post("https://medicard.com.ua/api/v3/register", d)
                .then((response) => {

                    const token = response.data.token;
                    const user = response.data.user;

                    context.commit("setRegistrationStep", 2);

                    if (token) {
                        localStorage.setItem("token", token);
                        axios.defaults.headers.common["X-Access-Token"] = token;
                    }

                    if (token && user) {
                        context.commit("authSuccess", token, user);
                    }
                })
                .catch((error) => {
                    if (error.status == 201) {
                        context.commit("setRegistrationStep", 2);
                    } else {
                        if (error.response.data.fields.phone == "Phone already exists") {
                            context.commit("showPhoneAlreadyExist", true);
                        } else {
                            if (error.response.data.fields.card == "Card already active") {
                                context.commit("showCardAlreadyExist", true);
                            } else {
                                if (error.response.data.fields.card == "Card doesn`t exists") {
                                    context.commit("showCardDoesntExist", true);
                                } else {
                                    context.setRegistrationStep(2);
                                }
                            }
                        }
                    }
                    context.commit("authError", error);
                    localStorage.removeItem("token");
                });
        },
        // Begin user logout
        async userLogout(context) {
            context.commit("logout");
            await axios
                .get("https://medicard.com.ua/api/v4/logout")
                .then(() => {
                    localStorage.removeItem("token");
                    delete axios.defaults.headers.common["X-Access-Token"];
                })
                .catch((error) => {
                    context.commit("authError", error);
                });
        },

        async getUserPositionByIP(context) {
            await axios
                .post(
                    `https://www.googleapis.com/geolocation/v1/geolocate?key=${context.state.googleApiKey}`
                )
                .then((response) => {

                    let coordinates = {
                        lat: Number(response.data.location.lat),
                        lng: Number(response.data.location.lng),
                    };
                    axios
                        .get(
                            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                            coordinates.lat +
                            "," +
                            coordinates.lng +
                            "&key=" +
                            context.state.googleApiKey
                        )
                        .then((response) => {
                            var cityName =
                                response.data.results[0].address_components[3].long_name;
                            var countryName = [
                                ...response.data.results[0].address_components,
                            ][4]["short_name"];

                            context.commit("setUserLocation", {
                                coordinates,
                                cityName,
                                countryName,
                            });
                        });
                });
        },

        async getUserLocationByLatLng(context, {coordinates, position}) {
            await axios
                .get(
                    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                    position.coords.latitude +
                    "," +
                    position.coords.longitude +
                    "&key=" +
                    context.state.googleApiKey
                )
                .then((response) => {
                    var cityName =
                        response.data.results[0].address_components[2].long_name;
                    var countryName =
                        response.data.results[0].address_components[4].short_name;

                    context.commit("setUserLocation", {
                        coordinates,
                        cityName,
                        countryName,
                    });
                });
        },

        async getDrugStoresShowNearest(
            context,
            {Address, Letter, City, Lat, Lon, Radius, workAround}
        ) {
            var data = new FormData();
            data.append("Address", Address);
            data.append("Letter", Letter);
            data.append("City", City);
            data.append("Lat", Lat);
            data.append("Lon", Lon);
            data.append("Radius", Radius);
            data.append("WorkAround", workAround);
            await axios
                .post(
                    "https://terapia-plus-api.azurewebsites.net/api/Map/getDrugStores",
                    data
                )
                .then((res) => {
                    let data = res.data
                    context.commit("convertToTempMapMarkers", data);
                    var tempMapMarkers = context.state.pharmacies;
                    if (Radius == 0 && tempMapMarkers.length !== 0) {
                        var t = tempMapMarkers[0];
                        tempMapMarkers = [];
                        tempMapMarkers.push(t);
                    }
                    if (tempMapMarkers.length == 0) {
                        context.commit("showThereIsNoDrugStoresGoAway", true);
                    } else {
                        context.commit("setMapMarkers", tempMapMarkers);
                        if (tempMapMarkers.length == 1 || Radius == 0) {
                            context.commit("setNearestPharmacyModal", true);
                        }
                    }
                })
        },

        mapShow(context, data) {
            context.commit("showMap", data);
        },
        showMapBlock(context, data) {
            context.commit("showHideMapBlock", data);
        }
    },
    modules: {},
});
