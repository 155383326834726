<template>
  <div class="modal contacts-error" v-if="getContactsErrorModal">
    <div class="modal__content">
      <div class="modal__close" @click="setContactsError(false)"></div>
      <h3 class="modal__title">
        Вибачте, форма зворотнього зв'язку зараз не працює.
      </h3>
      <div class="modal__main">
        <div class="modal__text ">
          Зверніться, будь ласка,<br />
          до операторів
          <b>Гарячої лінії</b>
        </div>
        <div class="modal__tel">0 800 211 202</div>
        <div class="modal__subtext">
          Перепрошуємо за тимчасові <br />
          незручності
        </div>
        <!-- <div class="modal__options">
          <a
            href="#"
            class="modal__btn modal__btn_yellow"
            @click.prevent="setContactsError(false)"
          >
            Добре
          </a>
          <a
            href="#"
            class="modal__btn modal__btn_yellow"
            @click.prevent="searchNearestPharmacy()"
          >
            Шукати найближчу
          </a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "ContactsError",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getContactsErrorModal"])
  },
  methods: {
    ...mapMutations(["setContactsError"])
  },
  watch: {
    getContactsErrorModal: function() {}
  }
};
</script>
