<template>
  <section class="become-member">
    <h2 class="become-member__title" id="become_member">Як стати учасником</h2>
    <div class="container">
      <div :class="!getResponsive ? 'row' : 'become-member__list'">
        <div
          :class="
            !getResponsive ? 'col-xx-lg-4 col-x-lg-4 col-lg-4 col-md-4' : ''
          "
        >
          <div class="become-member__item become-member__item_one">
            <div class="become-member__item-number">I.</div>
            <h4 class="become-member__item-title">
              Отримайте картку учасника Програми ТерапіяПЛЮС
            </h4>
            <p class="become-member__item-text">
              Ви можете отримати стартовий пакет з карткою учасника Програми
              ТерапіяПЛЮС у лікаря закладу охорони здоров'я, якщо лікар
              призначив Вам лікарський засіб, який включений у
              <!-- <br />-->
              Програму ТерапіяПЛЮС.
            </p>
          </div>
        </div>
        <div
          :class="
            !getResponsive ? 'col-xx-lg-4 col-x-lg-4 col-lg-4 col-md-4' : ''
          "
        >
          <div class="become-member__item become-member__item_two">
            <div class="become-member__item-number">II.</div>
            <h4 class="become-member__item-title">
              Зареєструйтеся в Програмі ТерапіяПЛЮС
            </h4>
            <p class="become-member__item-text">
              Для того, щоб стати учасником Програми, Вам необхідно
              зареєструватись, надавши наступні дані: номер картки учасника
              Програми, Ваше повне ім’я (ПІБ), Ваш контактний номер телефону. Ви
              можете це зробити в розділі «Реєстрація» або зателефонувавши на
              гарячу лінію
              <strong>0 800 211 202</strong> (щоденно з 9:00 до 21:00).
            </p>
          </div>
        </div>
        <div
          :class="
            !getResponsive ? 'col-xx-lg-4 col-x-lg-4 col-lg-4 col-md-4' : ''
          "
        >
          <div class="become-member__item become-member__item_three">
            <div class="become-member__item-number">III.</div>
            <h4 class="become-member__item-title">
              Користуйтеся перевагами Програми ТерапіяПЛЮС
            </h4>
            <p class="become-member__item-text">
              Після завершення реєстрації Ви зможете користуватися Програмою, в
              тому числі можливістю придбати лікарський засіб, що призначений
              Вам лікарем та включений у Програму, за більш доступною ціною у
              будь-якій аптеці-учасниці Програми.
              <br />
              <strong>
                * Для отримання знижки на лікарський засіб, що входить до
                Програми, пред'явіть в
                <!-- <br />-->
                аптеці-учасниці Програми картку учасника Програми ТерапіяПЛЮС.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider";
import { mapGetters } from "vuex";

export default {
  name: "BecomeMember",
  components: {},
  computed: {
    ...mapGetters(["getResponsive"])
  },
  mounted() {
    if (this.getResponsive) {
      tns({
        container: ".become-member__list",
        items: 1,
        controls: false,
        autoplay: false
      });
    }
  }
};
</script>
