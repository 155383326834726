<template>
  <div class="modal contacts-success" v-if="canIShowCardAlreadyExist">
    <div class="modal__content">
      <div class="modal__close" @click="showCardAlreadyExist(false)"></div>
      <h3 class="modal__title">Ой!</h3>
      <div class="modal__main">
        <div class="modal__text no-drug-stores">
          Користувач з такою карткою вже зареєстрований!
        </div>

        <div class="modal__options">
          <a
            href="#"
            class="modal__btn modal__btn_yellow"
            @click.prevent="showCardAlreadyExist(false)"
            >Ок</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "CardAlreadyExist",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["canIShowCardAlreadyExist"])
  },
  methods: {
    ...mapMutations(["showCardAlreadyExist"])
  }
};
</script>
