<template>
  <section class="search-pharmacies">
    <div class="container">
      <div
          class="search-pharmacies__to-form"
          @click="backToForm"
          v-if="getResponsive && isVisibleMap"
      >
        Назад до форми пошуку
      </div>
    </div>
    <div :class="!getResponsive ? 'row' : ''">
      <div class="col-left col-xx-lg-6 col-x-lg-6 col-lg-6 col-md-6 col-xs-12">
        <div class="search-pharmacies__map">
          <SearchMap ref="searchMap"/>
        </div>
      </div>
      <div
          class="col-right col-xx-lg-6 col-x-lg-6 col-lg-6 col-md-6 col-xs-12"
          v-if="isVisibleForm"
      >

        <div class="hide" v-if="getHideMapBlock">
          <div class="btn" @click="mapShow(false)">знайти аптеку за адресою</div>
        </div>

        <div class="search-pharmacies__form">
          <div :class="getResponsive ? 'container' : ''">
            <div class="reverse-on-mobile">
              <div class="search-pharmacies__form-title">
                Знайти найближчу аптеку
              </div>
            </div>
            <div class="search-pharmacies__form-field">
              <label for="enter-address">Де ви перебуваєте?</label>
              <div class="search-pharmacies__form-group">
                <input
                    type="text"
                    autocomplete="off"
                    @input="handleAddress"
                    v-model="searchAddress"
                    id="enter-address"
                    spellcheck="false"
                    placeholder="Введіть адресу..."
                />
                <div
                    class="search-pharmacies__form-addresses"
                    v-if="searchAddressResults.length !== 0"
                >
                  <div
                      class="search-pharmacies__form-address"
                      v-for="(address, index) in searchAddressResults"
                      :key="index"
                      @click="selectAddress(index)"
                  >
                    {{ address.formatted_address }}
                  </div>
                </div>
              </div>
            </div>
            <div class="search-pharmacies__form-range">
              <label for="set-distance">
                На якій відстані шукати аптеки?
                <span v-if="getResponsive">(метрів)</span>
              </label>
              <veeno
                  v-model="radiusValue"
                  :pipsy="{ mode: 'steps', density: 10 }"
                  :set="[radiusValue]"
                  :handles="[radiusValue]"
                  :step="500"
                  :range="{ min: [0], max: [4000] }"
              />
            </div>
            <div class="search-pharmacies__form-checkbox">
              <input
                  type="checkbox"
                  id="work-around"
                  v-model="workAround"
                  placeholder
              />
              <label for="work-around">Аптеки, які працюють цілодобово</label>
            </div>
            <div class="search-pharmacies__form-footer">
              <div
                  class="search-pharmacies__form-submit"
                  :class="displayLoader ? 'loading' : ''"
              >
                <input
                    type="button"
                    @click="submitForm()"
                    value="Пошук"
                    :class="searchAddress.length == 0 ? 'disabled' : ''"
                />
                <div
                    class="search-pharmacies__form-submit-loader"
                    v-if="displayLoader"
                ></div>
              </div>
              <router-link
                  to="/pharmacies"
                  class="search-pharmacies__form-view-address"
              >
                Переглянути адреси усіx аптек
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SearchMap from "@/components/maps/SearchMap";
import veeno from "veeno";
import "nouislider/distribute/nouislider.min.css";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "SearchPharmacies",
  components: {
    SearchMap,
    veeno,
  },
  data() {
    return {
      displayLoader: false,
      isVisibleForm: true,
      isVisibleMap: true,
      searchAddress: "",
      searchAddressResults: [],
      lat: 0,
      lng: 0,
      score: 0,
      radiusValue: 0.0,
      workAround: false,
      drugStores: [],
    };
  },
  computed: {
    ...mapGetters([
      "getGoogleApiKey",
      "getHideMap",
      "getUserLocation",
      "getResponsive",
      'getGoogle',
      "getMapMarkers",
      "getDS",
      "getConvertedToTempMapMarkers",
      "getClearInputs",
      "getHideMapBlock"
    ]),
  },
  watch: {
    getClearInputs() {
      this.searchAddress = "";
      this.workAround = false;
      this.radiusValue = 0.0;
      this.setClearInputs(false);
    },
  },
  methods: {
    ...mapMutations([
      "setLocationAddressModal",
      "setMapMarkers",
      "setCurrentCoordinates",
      "setNearestPharmacyModal",
      "setDS",
      "convertToTempMapMarkers",
      "setClearInputs",
      "showThereIsNoDrugStoresGoAway",
    ]),

    ...mapActions(["mapShow"]),

    backToForm() {
      this.isVisibleMap = false;
      this.isVisibleForm = true;
    },

    showLocationAddressModal() {
      this.setLocationAddressModal(true);
    },
    async handleAddress() {

      if (this.searchAddress.length >= 3 && this.searchAddress.length > this.score) {
        this.score = this.searchAddress.length
        await this.axios
            .get(
                "https://maps.googleapis.com/maps/api/geocode/json?&key=" +
                this.getGoogleApiKey,
                {
                  params: {
                    address: this.searchAddress,
                    sensor: false,
                    language: "ua",
                    components: "country:ua",
                  },
                }
            )
            .then((response) => {
              if (this.searchAddress.length !== 0) {
                this.searchAddressResults = response.data.results;
              } else {
                this.searchAddressResults = [];
              }
            });
      } else {
        this.score = this.searchAddress.length
      }
    },

    selectAddress(index) {
      var fullAddressArr = this.searchAddressResults[
          index
          ].formatted_address.split(",");
      var temp = {
        name: `${fullAddressArr[0] == null ? "" : fullAddressArr[0]}${
            fullAddressArr[1] == null
                ? ""
                : fullAddressArr[0] == null
                    ? ""
                    : "," + fullAddressArr[1]
        }${
            fullAddressArr[2] == null
                ? ""
                : fullAddressArr[1] == null
                    ? ""
                    : "," + fullAddressArr[2]
        }${
            fullAddressArr[3] == null
                ? ""
                : fullAddressArr[2] == null
                    ? ""
                    : "," + fullAddressArr[3]
        }`,
        lat: this.searchAddressResults[0].geometry.location.lat,
        lng: this.searchAddressResults[0].geometry.location.lng,
      };
      this.searchAddress = temp.name;

      this.lat = temp.lat;
      this.lng = temp.lng;
      var coordinates = {lat: this.lat, lng: this.lng};
      localStorage.curLon = this.lng;
      localStorage.curLat = this.lat;
      this.setCurrentCoordinates(coordinates);
      this.searchAddressResults = [];
    },
    submitForm() {

      // eslint-disable-next-line no-undef
      gtag("event", "click", {event_category: "click_button_search"});

      if (this.searchAddressResults.length > 0) {
        this.selectAddress(0);
      }
      this.displayLoader = true;
      localStorage.setItem("workAround", this.workAround);
      if (this.getResponsive) {
        this.isVisibleMap = true;
        this.isVisibleForm = false;
      }

      this.$store
          .dispatch("getDrugStoresShowNearest", {
            Address: "",
            Letter: "",
            City: "",
            Lat: this.lat == 0 ? localStorage.curLat : this.lat,
            Lon: this.lng == 0 ? localStorage.curLon : this.lng,
            Radius: this.radiusValue,
            workAround: this.workAround,
          })
          .then(() => {
            this.displayLoader = false;
          });
    },
  },
  mounted() {
    if (this.getResponsive) {
      this.isVisibleMap = false;
    } else {
      this.isVisibleMap = true;
    }
    var radiusSliderValue = document.querySelector(".noUi-pips .noUi-value");
    radiusSliderValue.innerHTML = "Будь-де";
  },

};
</script>

<style lang="scss" scoped>

.col-right {
  position: relative;
}

.hide {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);

  .btn {
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    background: #FFD770;
    border: 1px solid #F0AB00;
    border-radius: 2px;

    color: #3C1053;
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    padding: 16px 24px;
    cursor: pointer;

    &:hover {
      background: #f0ab00;
      border: 1px solid #3c1053;
      color: #3c1053;
    }
  }
}
</style>
