<template>
  <div class="modal contacts-success" v-show="getContactsSuccessModal">
    <div class="modal__content">
      <div class="modal__close" @click="setContactsSuccess(false)"></div>
      <h3 class="modal__title">Дякуємо, що звернулися до нас</h3>
      <div class="modal__main">
        <div class="modal__text">
          Наші менеджери зв’яжуться з Вами найближчим часом у робочий час
          <br />(щоденно з 9.00 до 21.00)
        </div>
        <div class="modal__options">
          <a
            href="#"
            class="modal__btn modal__btn_yellow"
            @click="setContactsSuccess(false)"
            >Добре</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "ContactsSuccess",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getContactsSuccessModal"])
  },
  methods: {
    ...mapMutations(["setContactsSuccess"])
  },
  watch: {
    getContactsSuccessModal: function() {}
  }
};
</script>
