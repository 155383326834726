<template>
  <div class="modal outside-country" v-show="getOutsideCountryModal">
    <div class="modal__content">
      <div class="modal__close" @click.prevent="closeModal"></div>
      <h3 class="modal__title">Ви знаходитесь поза межами України</h3>
      <div class="modal__main">
        <div class="modal__text">
          Програма ТерапіяПЛЮС працює тільки на території України. Будь ласка,
          оберіть населений пункт в межах України або пропустіть цей крок.
        </div>
        <div class="modal__options">
          <a href="#" @click.prevent="closeModal" class="modal__btn">
            Пропустити
          </a>
          <a
            href="#"
            @click.prevent="selectCity"
            class="modal__btn modal__btn_yellow"
          >
            Обрати населений пункт
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "OutsideCountry",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getOutsideCountryModal"])
  },
  methods: {
    ...mapMutations(["setOutsideCountryModal", "setLocationAddressModal"]),
    closeModal() {
      this.setOutsideCountryModal(false);
    },
    selectCity() {
      this.closeModal();
      this.setLocationAddressModal(true);
    }
  }
};
</script>
