<template>
  <div class="modal location-address" v-show="getLocationAddressModal">
    <div class="modal__content">
      <div class="modal__close" @click="closeModal"></div>
      <h3 class="modal__title">Вкажіть своє місцезнаходження</h3>
      <div class="modal__main">
        <div class="modal__field" :class="city ? 'active' : ''">
          <input
            type="text"
            id="modal-address"
            autocomplete="off"
            v-model="searchAddress"
            @input="searchCity"
            placeholder="Населений пункт..."
          />
          <div
            class="modal__field-clear"
            @click="clearField"
            v-show="city"
          ></div>

          <div class="modal__cities-list" v-show="filteredCities.length !== 0">
            <vue-custom-scrollbar
              class="modal__cities-scroll-area"
              :settings="settings"
            >
              <div
                class="modal__cities-list-item"
                v-for="(city, index) in filteredCities"
                :key="index"
                @click="selectCity(city)"
              >
                {{ city.name }}
              </div>
            </vue-custom-scrollbar>
          </div>
        </div>
        <div class="modal__info" v-if="isNoResults">
          На жаль, у Вашому населеному пункті немає аптек, які приймають участь
          у Програмі
        </div>
        <div class="modal__info" v-if="isInvalidCity">
          Населеного пункту з такою назвою не існує.
        </div>
        <div class="modal__options" v-if="!isNoResults">
          <a
            href="#"
            class="modal__btn"
            :class="!closestExist ? 'modal__btn_disabled' : ''"
            @click.prevent="confirmLocation"
            >Підтвердити</a
          >

          <a href="#" @click.prevent="closeModal" class="modal__link"
            >Скасувати</a
          >
        </div>
        <div class="modal__options" v-if="isNoResults">
          <a
            href="#"
            class="modal__btn modal__btn_yellow"
            @click.prevent="findClosest"
            >Шукати найближчу</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import vueCustomScrollbar from "vue-custom-scrollbar";
export default {
  name: "LocationAddress",
  data() {
    return {
      searchAddressResults: [],
      city: "",
      closestExist: false,
      selectedCity: "",
      isNoResults: false,
      isInvalidCity: false,
      filteredCities: [],
      settings: {
        maxScrollbarLength: 60,
      },
      searchAddress: "",
      lat: null,
      lng: null,
    };
  },
  components: {
    vueCustomScrollbar,
  },
  computed: {
    ...mapGetters([
      "getGoogleApiKey",
      "getLocationAddressModal",
      "getLocationConfirmModal",
      "getUserLocation",
      "getConvertedToTempMapMarkers",
    ]),
  },
  watch: {
    getLocationConfirmModal(isOpened) {
      if (!isOpened) {
        this.getDrugStores(
          "",
          "",
          "",
          localStorage.curLat, //from google by search address
          localStorage.curLon, //from google by search address
          10000, //closest
          false
        );
      }
      this.closeModal();
    },
  },
  methods: {
    ...mapMutations([
      "setLocationAddressModal",
      "setUserLocation",
      "setMapMarkers",
      "setLocationConfirmModal",
      "convertToTempMapMarkers",
      "setNearestPharmacyModal",
      "setDS",
      "setOpenMarkerOnMap",
      "setClearInputs",
    ]),
    clearField() {
      this.city = "";
      this.searchAddress = "";
      this.filteredCities = [];
    },
    closeModal() {
      this.setLocationAddressModal(false);
    },
    findClosest() {
      this.getDrugStores(
        "",
        "",
        "",
        this.selectedCity.coordinates.lat, //from google by search address
        this.selectedCity.coordinates.lng, //from google by search address
        0, //closest
        false
      );
      this.closeModal();
      this.setLocationConfirmModal(false);
      this.setNearestPharmacyModal(true);
      this.setClearInputs(true);
    },
    selectCity(city) {
      this.city = city.name;
      this.searchAddress = city.name;
      this.filteredCities = [];
      this.selectedCity = {
        coordinates: {
          lat: Number(city.lat),
          lng: Number(city.lng),
        },
        cityName: city.name,
      };
      this.getDrugStores(
        "",
        "",
        "",
        city.lat, //from google by search address
        city.lng, //from google by search address
        10000,
        false
      );
    },
    confirmLocation() {
      this.setOpenMarkerOnMap(true);
      // if (this.searchAddressResults.length == 0) {
      //   this.searchCity();
      // }
      this.setUserLocation({
        coordinates: this.selectedCity.coordinates,
        cityName: this.searchAddressResults[0].address_components[0].long_name, //only city
        countryName: "UA",
      });
      this.setClearInputs(true);
      this.setLocationConfirmModal(false);
      // this.setNearestPharmacyModal(true);
      this.closeModal();
    },
    searchCity() {
      this.axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?&key=" +
            this.getGoogleApiKey,
          {
            params: {
              address: this.searchAddress,
              sensor: false,
              language: "ua",
              components: "country:ua",
            },
          }
        )
        .then((response) => {
          if (response.data.results.length > 0)
            this.searchAddressResults = response.data.results;
          if (this.searchAddressResults.length !== 0) {
            var fullAddressArr = this.searchAddressResults[0].formatted_address.split(
              ","
            );
            var temp = {
              name: `${fullAddressArr[0] == null ? "" : fullAddressArr[0]}${
                fullAddressArr[1] == null
                  ? ""
                  : fullAddressArr[0] == null
                  ? ""
                  : "," + fullAddressArr[1]
              }${
                fullAddressArr[2] == null
                  ? ""
                  : fullAddressArr[1] == null
                  ? ""
                  : "," + fullAddressArr[2]
              }${
                fullAddressArr[3] == null
                  ? ""
                  : fullAddressArr[2] == null
                  ? ""
                  : "," + fullAddressArr[3]
              }`,
              lat: this.searchAddressResults[0].geometry.location.lat,
              lng: this.searchAddressResults[0].geometry.location.lng,
            };

            localStorage.curLon = temp.lng;
            localStorage.curLat = temp.lat;
            this.$store.commit("setUserLocation", {
              coordinates: {
                lat: temp.lat,
                lng: temp.lng,
              },
              cityName: this.searchAddressResults[0].address_components[0]
                .long_name,
              countryName: "UA",
            });
            var tempList = [];
            tempList.push(temp);
            this.filteredCities = tempList;
          } else {
            // this.searchAddressResults = [];
          }
        });
    },
    async getDrugStores(Address, Letter, City, Lat, Lon, Radius, workAround) {
      this.closestExist = false;
      var data = new FormData();
      data.append("Address", Address);
      data.append("Letter", Letter);
      data.append("City", City);
      data.append("Lat", Lat);
      data.append("Lon", Lon);
      data.append("Radius", Radius);
      await this.axios
        .post(
          "https://terapia-plus-api.azurewebsites.net/api/Map/getDrugStores",

          data
        )
        .then((res) => {

          if (workAround) {
            this.drugStores = res.data; // аптеки, які працюють цілодобово, я хз як воно в базі має буть написано. коли буде база, то і фільтр буде
          } else {
            this.drugStores = res.data;
          }
          this.setDS(this.drugStores);
          this.convertToTempMapMarkers(this.drugStores);
          var tempMapMarkers = this.getConvertedToTempMapMarkers;
          if (Radius == 0) {
            var t = tempMapMarkers[0];
            tempMapMarkers = [];
            tempMapMarkers.push(t);
          }
          if (tempMapMarkers.length != 0) {
            this.isNoResults = false;
            if (Radius == 0 || tempMapMarkers.length != 0) {
              this.closestExist = true;
            }
          } else {
            this.isNoResults = true;
          }
          this.setMapMarkers(tempMapMarkers); //зберігаю перших
        });
    },
  },
};
</script>
