<template>
  <div class="modal hotline" v-show="getHotlineModal">
    <div class="modal__content">
      <div class="modal__close"></div>
      <h3 class="modal__title">Шановний відвідуваче!</h3>
      <div class="modal__main">
        <div class="modal__text">
          Наразі гаряча лінія не працює. Ми робимо усе можливе, щоб скоріше
          відновити її роботу. Дякуємо за розуміння.
        </div>
        <div class="modal__options">
          <a
            href="#"
            class="modal__btn modal__btn_yellow"
            @click="setHotline(false)"
            >Зрозуміло</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Hotline",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getHotlineModal"])
  },
  methods: {
    ...mapMutations(["setHotline"])
  },
  watch: {
    getHotlineModal: function() {}
  }
};
</script>
