<template>
  <div class="modal contacts-success" v-if="canIShowThereIsNoDrugStoresGoAway">
    <div class="modal__content">
      <div
        class="modal__close"
        @click="showThereIsNoDrugStoresGoAway(false)"
      ></div>
      <h3 class="modal__title">Ой!</h3>
      <div class="modal__main">
        <div class="modal__text no-drug-stores" v-if="workAround">
          На жаль, за вашим запитом ми не знайшли цілодобових аптек, що беруть
          участь у програмі.
        </div>
        <div class="modal__text no-drug-stores" v-else>
          На жаль, за вашим запитом ми не знайшли аптек, що беруть участь у
          програмі.
        </div>
        <div class="modal__options">
          <a
            href="#"
            class="modal__btn modal__btn_yellow"
            @click.prevent="showThereIsNoDrugStoresGoAway(false)"
          >
            Добре
          </a>
          <a
            href="#"
            class="modal__btn modal__btn_yellow"
            @click.prevent="searchNearestPharmacy()"
          >
            Шукати найближчу
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "ThereIsNoDrugStores",
  data() {
    return {
      workAround: false
    };
  },
  computed: {
    ...mapGetters(["canIShowThereIsNoDrugStoresGoAway"])
  },
  methods: {
    ...mapMutations(["showThereIsNoDrugStoresGoAway"]),
    searchNearestPharmacy() {
      this.showThereIsNoDrugStoresGoAway(false);
      this.$store
        .dispatch("getDrugStoresShowNearest", {
          Address: "",
          Letter: "",
          City: "",
          Lat: localStorage.curLat,
          Lon: localStorage.curLon,
          Radius: 0,
          workAround: this.workAround
        })
        .then(() => {
          this.displayLoader = false;
        });
    }
  },
  updated() {
    this.workAround = JSON.parse(localStorage.getItem("workAround"));
  }
};
</script>
