<template>
  <section class="faq">
    <div class="container">
      <h2 class="faq__title">Поширені запитання</h2>
      <div :class="getResponsive ? 'faq__list' : 'row'">
        <div class="col-left col-xx-lg-6 col-x-lg-6 col-lg-6 col-xs-12">
          <div class="faq__item">
            <h4 class="faq__item-title">
              Як отримати картку учасника Програми ТерапіяПЛЮС?
            </h4>
            <div class="faq__item-content">
              <div class="faq__item-text">
                <p>
                  Картку учасника Програми ТерапіяПЛЮС Ви можете отримати у
                  лікаря за умови, якщо Вам було призначено лікарський засіб, що
                  включений до Програми.
                </p>
              </div>
            </div>
          </div>
          <div class="faq__item">
            <h4 class="faq__item-title">
              Які аптеки беруть участь у Програмі ТерапіяПЛЮС?
            </h4>
            <div class="faq__item-content">
              <div class="faq__item-text">
                <p>
                  Ви зможете користуватися Програмою, в тому числі можливістю
                  придбати лікарський засіб, що призначений Вам лікарем та
                  включений у Програму, за більш доступною ціною у будь-якій
                  аптеці-учасниці Програми.
                </p>
                <p>
                  Перелік аптек-учасниць Програми постійно оновлюється.
                  Дізнатися про те, чи бере конкретна аптека участь у Програмі,
                  Ви можете
                  <a style="font-weight:bold; color:#3C1053;" href="/pharmacies"
                    >на сайті</a
                  >
                  або зателефонувавши на гарячу лінію 0 800 211 202 (щоденно з
                  9:00 до 21:00)
                </p>
              </div>
            </div>
          </div>
          <div class="faq__item">
            <h4 class="faq__item-title">
              Чи можу я користуватися карткою ТерапіяПЛЮС без реєстрації?
            </h4>
            <div class="faq__item-content">
              <div class="faq__item-text">
                <p>
                  Ні. Для того, щоб мати можливість користуватися перевагами
                  Програми ТерапіяПЛЮС, Ви повинні зареєструватись в Програмі.
                </p>
                <p>
                  Зареєструватися у Програмі Ви можете онлайн в розділі
                  <a
                    style="font-weight:bold; color:#3C1053;"
                    href="/registration"
                    >«Реєстрація»</a
                  >
                  цілодобово або зателефонувавши на гарячу лінію 0 800 211 202
                  (щоденно з 9:00 до 21:00).
                </p>
                <p>
                  В процесі реєстрації Вам потрібно буде надати наступні дані:
                  номер картки учасника Програми, Ваше повне ім’я (ПІБ), Ваш
                  контактний номер телефону.
                </p>
              </div>
            </div>
          </div>
          <div class="faq__item">
            <h4 class="faq__item-title">
              Що робити, якщо я маю декілька Карток Програми?
            </h4>
            <div class="faq__item-content">
              <div class="faq__item-text">
                <p>
                  У Програмі діє правило «один учасник – одна картка». Це
                  означає, що один учасник може зареєструватися у Програмі лише
                  за однією карткою та відповідно користуватися однією карткою.
                  Не дозволяється реєстрація та використання більше ніж однієї
                  картки для однієї особи.
                </p>
                <p>
                  Будь ласка, зателефонуйте за телефоном гарячої лінії лінію
                  <a
                    style="font-weight:bold; color:#3C1053;"
                    href="tel:0800211202"
                  >
                    0 800 211 202
                  </a>
                  (щоденно з 9:00 до 21:00) та повідомте номер картки, якою Ви
                  плануєте користуватися.
                </p>
                <p>
                  Також назвіть номери інших карток, які Ви маєте, для їхнього
                  блокування.
                </p>
              </div>
            </div>
          </div>
          <div class="faq__item">
            <h4 class="faq__item-title">
              Як припинити свою участь у Програмі ТерапіяПлюс?
            </h4>
            <div class="faq__item-content">
              <div class="faq__item-text">
                <p>
                  Якщо Ви бажаєте припинити свою участь у Програмі ТерапіяПлюс,
                  будь-ласка, повідомте про це Адміністратора Програми за
                  контактами гарячої лінії 0 800 211 202 (щоденно з 9:00 до
                  21:00) або онлайн через
                  <a style="font-weight:bold; color:#3C1053;" href="#feedback"
                    >форму зворотного зв’язку</a
                  >. Після отримання такого запиту картку учасника Програми буде
                  анульовано і вона буде недоступною для використання.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-right col-xx-lg-6 col-x-lg-6 col-lg-6 col-xs-12">
          <div class="faq__item">
            <h4 class="faq__item-title">
              Які лікарські засоби входять у Програму ТерапіяПЛЮС?
            </h4>
            <div class="faq__item-content">
              <div class="faq__item-text">
                <p>
                  Щоб дізнатися, чи включений лікарський засіб у Програму, та
                  розмір знижки на препарат згідно Програми, введіть назву
                  препарату, який був призначений Вам лікарем, в поле
                  <a
                    style="font-weight:bold; color:#3C1053;"
                    href="#participants-savings"
                    >Пошук</a
                  >.
                </p>
              </div>
            </div>
          </div>
          <div class="faq__item">
            <h4 class="faq__item-title">
              Яку економію можна отримати на лікарські засоби, що включені у
              Програму?
            </h4>
            <div class="faq__item-content">
              <div class="faq__item-text">
                <p>
                  Дізнатися про розмір знижки на лікарський засіб, що включений
                  у Програму, згідно Умов Програми, можна ввівши назву
                  препарату, який був призначений Вам лікарем, в поле
                  <a
                    style="font-weight:bold; color:#3C1053;"
                    href="#participants-savings"
                    >Пошук</a
                  >.
                </p>
                <p>
                  Зниження ціни (знижка) на лікарський засіб, що бере участь у
                  Програмі, розраховується від роздрібної ціни на такий
                  лікарський засіб, яка встановлена аптечним закладом. При цьому
                  аптечні заклади самостійно і на власний розсуд встановлюють
                  ціни на лікарські засоби, в тому числі на лікарські засоби, що
                  включені у Програму. В зв’язку з цим абсолютний розмір знижки
                  за Програмою, а також остаточна ціна лікарського засобу після
                  врахування знижки за Програмою може відрізнятися у різних
                  аптеках.
                </p>
                <p>
                  Організатор Програми залишає за собою право змінювати розмір
                  знижки за Програмою у будь-який час на власний розсуд та без
                  попередження учасників Програми.
                </p>
              </div>
            </div>
          </div>
          <div class="faq__item">
            <h4 class="faq__item-title">
              Що робити, якщо я втратив або пошкодив картку?
            </h4>
            <div class="faq__item-content">
              <div class="faq__item-text">
                <p>
                  У випадку втрати або серйозного пошкодження картки, через яке
                  нею неможливо користуватися (наприклад, виникають складнощі із
                  скануванням або зчитуванням штрих-коду в аптеці), Вам слід:
                </p>
                <p>
                  1. Повідомити про це Адміністратора Програми за телефоном
                  гарячої лінії 0 800 211 202 (щоденно з 9:00 до 21:00) або
                  онлайн через
                  <a style="font-weight:bold; color:#3C1053;" href="#feedback"
                    >форму зворотного зв’язку</a
                  >.
                </p>
                <p>
                  2. Звернутися до Вашого лікаря для отримання нової картки
                  учасника Програми.
                </p>
              </div>
            </div>
          </div>
          <div class="faq__item">
            <h4 class="faq__item-title">
              Чому я отримую смс-нагадування та як їх можна відключити?
            </h4>
            <div class="faq__item-content">
              <div class="faq__item-text">
                <p>
                  При реєстрації у Програмі Ви підтвердили згоду отримувати
                  комунікації щодо Програми.
                </p>
                <p>
                  Якщо Ви не бажаєте отримувати комунікації щодо Програми,
                  необхідно повідомити про це Адміністратора Програми у розділі
                  <a style="font-weight:bold; color:#3C1053;" href="#feedback"
                    >«Зворотний зв'язок»</a
                  >, написавши запит із вказанням Вашого номеру телефону/емейлу
                  для виключення Ваших контактів зі списку розсилок.
                </p>
                <p>
                  Після виключення Ви не будете отримувати повідомлення щодо
                  Програми.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="faq__show-more" v-if="getResponsive">
        <a href="#" @click.prevent="showMore($event)">Показати більше</a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Faq",
  data() {
    return {
      showFaqOnMobile: 4
    };
  },
  computed: {
    ...mapGetters(["getResponsive"])
  },
  methods: {
    unwrap(wrapper) {
      var docFrag = document.createDocumentFragment();
      while (wrapper.firstChild) {
        var child = wrapper.removeChild(wrapper.firstChild);
        docFrag.appendChild(child);
      }
      wrapper.parentNode.replaceChild(docFrag, wrapper);
    },
    showMore(event) {
      var faqs = document.querySelectorAll(".faq__item");
      faqs.forEach(faq => {
        if (faq.classList.contains("hidden")) {
          faq.classList.remove("hidden");
        }
      });
      event.target.parentNode.classList.add("hidden");
    }
  },
  mounted() {
    if (this.getResponsive) {
      // unwrap elements
      this.unwrap(document.querySelector(".faq .col-left"));
      this.unwrap(document.querySelector(".faq .col-right"));

      // show 4 elements on mobile
      var faqs = document.querySelectorAll(".faq__item");
      faqs.forEach((faq, index) => {
        if (index >= this.showFaqOnMobile) {
          faq.classList.add("hidden");
        }
      });
    }
    // listeners for faqs
    var accordions = document.querySelectorAll(".faq__item-title");
    accordions.forEach(accordion => {
      accordion.addEventListener("click", () => {
        if (!accordion.parentNode.classList.contains("active")) {
          accordions.forEach(accordion => {
            accordion.parentElement.classList.remove("active");
            accordion.parentElement.children[1].setAttribute(
              "style",
              "height:0px"
            );
          });
        }
        if (accordion.parentNode.classList.contains("active")) {
          accordion.parentElement.classList.remove("active");
          accordion.parentElement.children[1].setAttribute(
            "style",
            "height:0px"
          );
        } else {
          accordion.parentElement.classList.add("active");
          var contentHeight =
            accordion.parentElement.children[1].children[0].clientHeight;
          accordion.parentElement.children[1].setAttribute(
            "style",
            "height:" + contentHeight + "px"
          );
        }
      });
    });
  }
};
</script>
