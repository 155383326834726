<template>
  <section class="feedback">
    <Hotline />
    <ContactsSuccess />
    <ContactsError />
    <div class="container">
      <h2 class="feedback__title" name="feedback" id="feedback">
        Зворотний зв’язок
      </h2>
      <h5 class="feedback__subtitle">
        Із запитаннями щодо Програми ТерапіяПЛЮС
        <br />Ви можете звертатись до Адміністратора Програми
      </h5>
      <div class="row">
        <div class="col-xx-lg-6 col-x-lg-6 col-lg-6 col-md-6 col-xs-12">
          <div class="feedback__consultant">
            <div class="feedback__consultant-title">
              за телефоном Гарячої лінії
            </div>
            <a href="tel:0 800 211 202" class="feedback__consultant-phone"
              >0 800 211 202</a
            >
            <div class="feedback__consultant-timework">
              (щоденно з 9:00 до 21:00).
            </div>
            <div class="feedback__consultant-info">
              Дзвінки з усіх телефонних номерів
              <br />в межах України
              <strong>безкоштовні.</strong>
            </div>
          </div>
        </div>
        <div class="col-xx-lg-6 col-x-lg-6 col-lg-6 col-md-6 col-xs-12">
          <div class="feedback__form">
            <div
              class="feedback__form-field"
              :class="requiredName.isError ? 'error' : ''"
            >
              <label for="feedback-name">Iм'я</label>
              <input
                type="text"
                id="feedback-name"
                v-model="formData.userName"
                placeholder="Яна"
              />
              <div
                class="feedback__form-field-error"
                v-if="requiredName.isError"
              >
                {{ requiredName.message }}
              </div>
            </div>
            <div
              class="feedback__form-field"
              :class="requiredEmail.isError ? 'error' : ''"
            >
              <label for="feedback-email">E-mail</label>
              <input
                type="email"
                id="feedback-email"
                v-model="formData.userEmail"
                placeholder="Email.example@gmail.com"
              />
              <div
                class="feedback__form-field-error"
                :class="formData.userEmail.trim().length"
                v-if="requiredEmail.isError"
              >
                {{ requiredEmail.message }}
              </div>
            </div>
            <div
              class="feedback__form-field"
              :class="requiredEmail.isError ? 'error' : ''"
            >
              <label for="feedback-message">Повiдомлення</label>
              <input
                type="text"
                id="feedback-message"
                v-model="formData.userMessage"
                placeholder="Що ви хотіли спитати?"
              />
              <div
                class="feedback__form-field-error"
                v-if="requiredMessage.isError"
              >
                {{ requiredMessage.message }}
              </div>
            </div>
            <div class="feedback__form-checkbox">
              <input
                type="checkbox"
                checked
                id="feedback-privacy"
                v-model="userPrivacy"
                placeholder
              />
              <label for="feedback-privacy">
                Я надаю Адміністратору Програми право здійснювати обробку
                (включаючи збирання, зберігання, використання, видалення і т.д.)
                інформації, яку я вказав(ла) в даному повідомленні, з метою
                надання відповіді на моє запитання.
              </label>
              <div
                class="feedback__form-field-error"
                v-if="requiredPrivacy.isError"
              >
                {{ requiredPrivacy.message }}
              </div>
            </div>

            <input
              type="button"
              @click="submitForm"
              class="feedback__form-submit"
              value="Надіслати"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import Hotline from "@/components/modals/Hotline";
import ContactsSuccess from "@/components/modals/ContactsSuccess";
import ContactsError from "@/components/modals/ContactsError";

export default {
  name: "Feedback",
  components: {
    Hotline,
    ContactsSuccess,
    ContactsError
  },
  data() {
    return {
      userPrivacy: true,
      isError: false,
      isSuccess: false,
      formData: {
        userName: "",
        userEmail: "",
        userMessage: ""
      },
      requiredName: {
        isError: false,
        message: "Будь ласка, заповніть це поле"
      },
      requiredEmail: {
        isError: false,
        message: "Будь ласка, введіть валідну e-mail адресу"
      },
      requiredMessage: {
        isError: false,
        message: "Будь ласка, введіть повідомлення"
      },
      requiredPrivacy: {
        isError: false,
        message: "Ви не погодились з політикою конфіденційності"
      }
    };
  },
  methods: {
    ...mapMutations(["setContactsSuccess", "setHotline", "setContactsError"]),
    submitForm() {
      if (this.userPrivacy) {
        this.requiredPrivacy.isError = false;
        // Validate Name
        if (!this.formData.userName) {
          this.requiredName.isError = true;
        } else if (!this.validateName(this.formData.userName)) {
          this.requiredName.isError = true;
        } else {
          this.requiredName.isError = false;
        }
        // Validate Email
        if (!this.formData.userEmail) {
          this.requiredEmail.isError = true;
        } else if (!this.validateEmail(this.formData.userEmail)) {
          this.requiredEmail.isError = true;
        } else {
          this.requiredEmail.isError = false;
        }
        // Validate Ьуііфпу
        if (!this.formData.userMessage) {
          this.requiredMessage.isError = true;
        } else if (!this.validateMessage(this.formData.userMessage)) {
          this.requiredMessage.isError = true;
        } else {
          this.requiredMessage.isError = false;
        }
      } else {
        this.requiredPrivacy.isError = true;
      }
      // if form valid
      if (
        this.validateName(this.formData.userName) &&
        this.validateEmail(this.formData.userEmail) &&
        this.validateMessage(this.formData.userMessage) &&
        !this.requiredPrivacy.isError
      ) {
        // var data = {
        //   name: this.formData.userName,
        //   email: this.formData.userEmail,
        //   text: this.formData.userMessage
        // };
        // var dataArray = [
        //   this.formData.userName,
        //   this.formData.userEmail,
        //   this.formData.userMessage
        // ];
        // var dataArrayObj = [
        //   { name: this.formData.userName },
        //   { email: this.formData.userEmail },
        //   { text: this.formData.userMessage }
        // ];
        var fData = new FormData();
        fData.append("name", this.formData.userName);
        fData.append("email", this.formData.userEmail);
        fData.append("text", this.formData.userMessage);
        this.axios
          .post("https://medicard.com.ua/api/feedback.php", fData)
          .then(res => {
            if (res.status != "200") {
              this.setContactsError(true);
              this.setContactsSuccess(false);
            } else {
              this.setContactsError(false);
              this.setContactsSuccess(true);
            }
          });
      }
    },
    validateName(userName) {
      const reName = /^[А-Яа-яёЁЇїІіЄєҐґ]+$/;
      return reName.test(userName);
    },
    validateEmail(userEmail) {
      var reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reEmail.test(userEmail);
    },
    validateMessage(userMessage) {
      // const msg = /^[А-Яа-яёЁЇїІіЄєҐґ]+$/;
      return userMessage.length > 0;
    }
  }
};
</script>
