<template>
  <div class="modal contacts-success" v-if="canIShowCardDoesntExist">
    <div class="modal__content">
      <div class="modal__close" @click="showCardDoesntExist(false)"></div>
      <h3 class="modal__title">Ой!</h3>
      <div class="modal__main">
        <div class="modal__text no-drug-stores">Невірний номер картки!</div>

        <div class="modal__options">
          <a
            href="#"
            class="modal__btn modal__btn_yellow"
            @click.prevent="showCardDoesntExist(false)"
            >Ок</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "CardDoesntExist",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["canIShowCardDoesntExist"])
  },
  methods: {
    ...mapMutations(["showCardDoesntExist"])
  }
};
</script>
