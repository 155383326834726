<template>
  <section class="participants-savings">
    <div class="container">
      <div class="row">
        <div class="col-xx-lg-8 col-x-lg-8 col-lg-7 col-md-7 col-xs-12">
          <h2 class="participants-savings__title" id="participants-savings">
            Економія для учасників програми
          </h2>
          <div class="participants-savings__image" v-if="getResponsive">
            <img src="~@/assets/img/participants-savings-gran.png" alt />
          </div>
          <h3 class="participants-savings__subtitle">
            Щоб перевірити, чи входить препарат у Програму, будь ласка, введіть
            його назву
          </h3>
          <div class="participants-savings__form">
            <div class="participants-savings__form-field">
              <input
                type="text"
                v-model.trim="preparat"
                placeholder="Введіть назву препарата..."
                v-on:keyup.enter="findPreparat()"
              />
              <div
                class="participants-savings__form-clear"
                v-if="preparat"
                @click="clearField"
              ></div>
            </div>
            <div class="participants-savings__form-submit">
              <input type="button" @click="findPreparat()" value="Перевірити" />
            </div>
          </div>
          <div
            class="participants-savings__results-none"
            v-if="finedPreparats.length == 0 && showResult"
          >
            Препарат не знайдено або він не бере участі в нашій програмі.
          </div>
          <div
            class="participants-savings__results"
            v-if="finedPreparats.length"
          >
            <div class="participants-savings__results-title">
              Результати пошуку
            </div>

            <div class="participants-savings__results-list">
              <div
                class="participants-savings__results-item"
                v-for="(preparat, index) in finedPreparats"
                :key="index"
              >
                <div class="participants-savings__results-detail">
                  <div class="participants-savings__results-name">
                    {{ preparat.name }}
                  </div>
                  <div class="participants-savings__results-text">
                    {{ preparat.text }}
                  </div>
                </div>
                <div class="participants-savings__results-discount">
                  -{{ preparat.discount }}
                  <span>%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="participants-savings__info">
            <ul>
              <li>
                Знижка розраховується від роздрібної ціни на препарат в аптеці,
                що бере участь у Програмі ТерапіяПЛЮС.
              </li>
              <li>
                Правила участі в Програмі та розміри знижок можуть змінюватись.
              </li>
              <li>
                Програма може бути призупинена чи завершена без попередження
                учасників.
              </li>
            </ul>
          </div>
        </div>
        <div
          class="col-xx-lg-4 col-x-lg-4 col-lg-5 col-md-5"
          v-if="!getResponsive"
        >
          <div class="participants-savings__image">
            <img src="~@/assets/img/participants-savings-gran.png" alt />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "",
  data() {
    return {
      preparat: "",
      showResult: false,
      finedPreparats: []
    };
  },
  computed: {
    ...mapGetters(["getPreparats", "getResponsive"])
  },
  methods: {
    clearField() {
      this.preparat = "";
    },
    findPreparat() {
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "click_button_check" });

      this.showResult = true;
      // if (this.preparat.length >= 3)
      //   this.finedPreparats = this.getDrugs(
      //     this.getPreparats,
      //     this.preparat.toLowerCase()
      //   );
      // else {
        if (this.preparat.length > 0) {
          this.preparat = this.preparat.replace(/\s+/g, ' ');

          this.finedPreparats = this.getPreparats.filter(p => {
            const name = p.name.toLowerCase();
            const text = p.text.toLowerCase();
            const preparat = this.preparat.toLowerCase();
            return name.includes(preparat) === true || text.includes(preparat) === true;
          });
        } else {
          this.finedPreparats = [];
        }
      // }
    },
    // getDrugs(drugs, word) {
      // var str = "";
      // //входження трьох підряд символів з першого рядка в другий рядок.
      // for (var i = 0; i < word.length; i++) {
      //   str += "(.*" + word[i] + word[i + 1] + word[i + 2] + ".*)" + "|";
      // }

      // str += str = str.slice(0, str.length - 1);
      // // перевірка по
      // var resDrugs = [];

      // drugs.forEach(e => {
      //   if (e.name.toLowerCase().includes(word.toLowerCase())) {
      //     resDrugs.push(e);
      //   }
      // });
      // if (resDrugs.length == 0) {
      //   drugs.forEach(e => {
      //     let result = e.name.toLowerCase().match(str);
      //     let result2 = e.text.toLowerCase().match(str);

      //     if (
      //       (result != "" && result != null) ||
      //       (result2 != "" && result2 != null)
      //     ) {
      //       resDrugs.push(e);
      //     }
      //   });
      // }
      // return resDrugs;
    // }
  }
};
</script>
