import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("../views/Registration.vue")
  },
  {
    path: "/pharmacies",
    name: "Pharmacies",
    component: () => import("../views/Pharmacies.vue")
  },
  {
    path: "/information",
    name: "Information",
    component: () => import("../views/Information.vue")
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("../views/Contacts.vue")
  },

  {
    path: "/adverse-reaction",
    name: "AdverseReaction",
    component: () => import("../views/AdverseReaction.vue")
  },
  {
    path: "/terms-and-conditions",
    name: "Conditions",
    component: () => import("../views/Conditions.vue")
  },
  {
    path: "/policy",
    name: "Policy",
    component: () => import("../views/Policy.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash !== "" && to.hash !== "#") {
      var elementId = to.hash.substring(1);

      var scrollOffset = document.getElementById(elementId).offsetTop;
      return { x: 0, y: scrollOffset };
    } else {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    }
  }
});

export default router;
