<template>
  <div class="modal confirm-location" v-show="getLocationConfirmModal">
    <div class="modal__content">
      <div class="modal__close" @click="closeModal"></div>
      <h3 class="modal__title">Підтвердіть своє місцезнаходження</h3>
      <div class="modal__main modal__main_small">
        <div class="modal__map">
          <LocationMap />
        </div>
        <div class="modal__options">
          <a
            href="#"
            class="modal__btn modal__btn_yellow"
            @click.prevent="confirmLocation"
            >Підтвердити</a
          >
          <a
            href="#"
            class="modal__link modal__link_blue"
            @click="changeLocation"
            >Змінити</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationMap from "@/components/maps/LocationMap";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "LocationConfirm",
  components: {
    LocationMap
  },
  computed: {
    ...mapGetters(["getLocationConfirmModal", "geolocationOpened"])
  },
  methods: {
    ...mapMutations([
      "setLocationConfirmModal",
      "setLocationAddressModal",
      "setNearestPharmacyModal"
    ]),
    closeModal() {
      this.setLocationConfirmModal(false);
    },
    confirmLocation() {
      this.setLocationConfirmModal(false);
      if (this.geolocationOpened) {
        this.setNearestPharmacyModal(true);
      }
    },
    changeLocation() {
      this.setLocationAddressModal(true);
      //this.setLocationConfirmModal(false);
    }
  }
};
</script>
