<template>
  <main>
    <div class="reverse-on-mobile">
      <WelcomeBanner/>
      <SearchPharmacies/>
    </div>
    <AboutTeraphy/>
    <ParticipantsSavings/>
    <BecomeMember/>
    <GetBenefits/>
    <ProgramRules/>
    <CreateAccount/>
    <Faq/>
    <Feedback/>
  </main>
</template>

<script>
import WelcomeBanner from "@/components/home/WelcomeBanner";
import SearchPharmacies from "@/components/home/SearchPharmacies";
import AboutTeraphy from "@/components/home/AboutTeraphy";
import ParticipantsSavings from "@/components/home/ParticipantsSavings";
import BecomeMember from "@/components/home/BecomeMember";
import GetBenefits from "@/components/home/GetBenefits";
import ProgramRules from "@/components/home/ProgramRules";
import CreateAccount from "@/components/home/CreateAccount";
import Faq from "@/components/home/Faq";
import Feedback from "@/components/home/Feedback";
import {mapActions} from "vuex";

export default {
  name: "HomeView",
  components: {
    WelcomeBanner,
    SearchPharmacies,
    AboutTeraphy,
    ParticipantsSavings,
    BecomeMember,
    GetBenefits,
    ProgramRules,
    CreateAccount,
    Faq,
    Feedback
  },
  mounted() {
    // eslint-disable-next-line no-undef
    gtag("event", "onLoad", {event_category: "open_main_page"});
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/views/_home";
</style>
