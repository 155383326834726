<template>
  <div class="header-wrapper">
    <header class="header">
      <div class="container">
        <div class="row">
          <div class="col-xx-lg-2 col-x-lg-2 col-lg-2 col-xs-6">
            <router-link to="/" class="header__logo">
              <img src="~@/assets/img/logo.png" alt />
            </router-link>
          </div>

          <div
            class="col-xx-lg-6 col-x-lg-5 col-lg-5 col-xs-6"
            v-if="!isEnableResponsive"
          >
            <nav class="header__nav">
              <router-link to="/#about-teraphy">Про програму</router-link>
              <router-link to="/pharmacies">Аптеки</router-link>
              <router-link to="/information">Інформація</router-link>
              <router-link to="/contacts">Контакти</router-link>
            </nav>
          </div>
          <div
            class="col-xx-lg-4 col-x-lg-5 col-lg-5"
            v-if="!isEnableResponsive"
          >
            <div class="header__options">
              <div class="header__info">
                <a href="tel:0 800 211 202" class="header__info-phone"
                  >0 800 211 202</a
                >
                <div class="header__info-timework">щоденно з 9:00 до 21:00</div>
              </div>
              <div class="header__account">
                <router-link
                  to="/login"
                  class="header__account-btn header__account-btn_yellow"
                  >Увійти</router-link
                >
                <router-link to="/registration" class="header__account-btn"
                  >Реєстрація</router-link
                >
              </div>
            </div>
          </div>

          <!-- Begin mobile nav -->
          <div class="col-xs-6" v-if="isEnableResponsive">
            <div class="header__mobile-menu">
              <div class="mobile-menu" @click="toggleMobileMenu">
                <div
                  class="mobile-menu__icon"
                  :class="isMenuVisible ? 'active' : ''"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="header__mobile"
            v-if="isEnableResponsive"
            v-show="isMenuVisible"
          >
            <div class="container">
              <nav class="header__mobile-nav">
                <router-link to="/#about-teraphy">Про програму</router-link>
                <router-link to="/pharmacies">Аптеки</router-link>
                <router-link to="/information">Інформація</router-link>
                <router-link to="/contacts">Контакти</router-link>
              </nav>
              <div class="header__account">
                <router-link
                  to="/login"
                  class="header__account-btn header__account-btn_yellow"
                  >Увійти</router-link
                >
                <router-link to="/registration" class="header__account-btn"
                  >Реєстрація</router-link
                >
              </div>
              <router-link to="/adverse-reaction" class="header__mobile-link"
                >Повідомити про побічну реакцію</router-link
              >
            </div>
          </div>
          <!-- // -->
        </div>
      </div>
    </header>
    <div class="header__contact-info" v-if="isVisibleContacts">
      <div class="container">
        <a href="tel:0 800 211 202">0 800 211 202</a>
        щоденно з 9:00 до 21:00
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      isVisibleContacts: false,
      isEnableResponsive: false,
      isMenuVisible: false
    };
  },
  computed: {
    ...mapGetters(["getResponsive"])
  },
  methods: {
    toggleMobileMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    }
  },
  beforeMount() {
    if (window.innerWidth < 1200) {
      this.isEnableResponsive = true;
    }
    if (window.innerWidth < 992) {
      this.isVisibleContacts = true;
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1200) {
        this.isEnableResponsive = true;
      } else {
        this.isEnableResponsive = false;
      }

      if (window.innerWidth < 992) {
        this.isVisibleContacts = true;
      } else {
        this.isVisibleContacts = false;
      }
    });
    this.$router.beforeEach((to, from, next) => {
      this.isMenuVisible = false;
      next();
    });
  }
};
</script>
