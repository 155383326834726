import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies";

//var VueResource = require('vue-resource');

Vue.config.productionTip = false;
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

Vue.use(VueAxios, axios);
Vue.use(VueCookies);
//Vue.use(VueResource);
Vue.$cookies.config("7d", "", "", true);
//Vue.http.options.root = '/root';
//Vue.http.headers.common['X-Frame-Options'] = "deny";
//Vue.http.headers.common['Cache-Control'] = "max-age=10";
//Vue.http.headers.common['X-Powered-By'] = "n";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
