<template>
  <section class="get-benefits">
    <div class="get-benefits__bg"></div>
    <div class="get-benefits__bg"></div>
    <div class="get-benefits__bg"></div>
    <div class="container">
      <h2 class="get-benefits__title">
        Бажаєте отримати <br />
        усі переваги від участі у Програмі
        <span>ТерапіяПлюс?</span>
      </h2>
      <router-link
        @click.native="registrationClick()"
        to="/registration"
        class="get-benefits__btn"
      >
        Зареєструватися
      </router-link>
    </div>
  </section>
</template>
<script>
export default {
  methods: {
    registrationClick() {
      // eslint-disable-next-line no-undef
      gtag("event", "click", { event_category: "click_button_register" });
    }
  }
};
</script>
